export default {
  moneySymbol: '￥',
  tableForm: {
    search: '查询',
    reset: '重置',
    submit: '提交',
    collapsed: '展开',
    expand: '收起',
    inputPlaceholder: '请输入',
    selectPlaceholder: '请选择',
  },
  alert: {
    clear: '清空',
    selected: '已选择',
    item: '项',
  },
  pagination: {
    total: {
      range: '第',
      total: '条/总共',
      item: '条',
    },
  },
  tableToolBar: {
    leftPin: '固定到左边',
    rightPin: '固定到右边',
    noPin: '取消固定',
    leftFixedTitle: '固定在左侧',
    rightFixedTitle: '固定在右侧',
    noFixedTitle: '不固定',
    reset: '重置',
    columnDisplay: '列展示',
    columnSetting: '列设置',
    fullScreen: '全屏',
    exitFullScreen: '退出全屏',
    reload: '刷新',
    density: '密度',
    densityDefault: '正常',
    densityLarger: '默认',
    densityMiddle: '中等',
    densitySmall: '紧凑',
  },
};
