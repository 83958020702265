export const COLORS = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

export const DATA_TYPES = ['string', 'number', 'date', 'boolean']
export const DISPLAY = ['image']
export const DEFAULT_MODEL_SELECT_FIELD = 'id,name'
export const DEFAULT_PAGE_EDITOR_ID = process.env.REACT_APP_PAGE_EDITOR_ID
  ? Number(process.env.REACT_APP_PAGE_EDITOR_ID)
  : 4
export const DEFAULT_PAGE_SETTING_ID = process.env.REACT_APP_PAGE_SETTING_ID
  ? Number(process.env.REACT_APP_PAGE_SETTING_ID)
  : 48
export const IS_DEBUG = process.env.IS_DEBUG
