import React from 'react'
import { router } from 'dva'
import asyncComponent from '@src/util/asyncComponent'
import Main from './main/index'
// import { routes } from '@src/routes'
import ListViewer from './default/list/ListViewer'
import PageEditor from './default/pageManager/PageEditor'
import FormViewer from './default/form/FormViewer'
/* PLOP_INJECT_IMPORT */

interface AppProps {
  match: any
}

const { Route, Switch } = router
const App: React.FC<AppProps> = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`/dashboard`}
          component={asyncComponent(() => import('./home'))}
        />
        <Route path={`${match.url}main`} component={Main} />

        {/* templates */}
        <Route
          path={`/pageEditor`}
          // component={asyncComponent(() => import('./default/pageManager/PageEditor'))}
          component={PageEditor}
        />
        <Route
          path={`/form`}
          // component={asyncComponent(() => import('./default/form/FormViewer'))}
          component={FormViewer}
        />
        <Route
          path={`/list`}
          // component={asyncComponent(() => import('./default/list/ListViewer'))}
          component={ListViewer}
        />
        {/* {routes.map((route: any, idx: number) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={(props) => <route.component {...props} />}
            />
          ) : null
        })} */}
        {/* PLOP_INJECT_EXPORT */}
      </Switch>
    </div>
  )
}

export default App
