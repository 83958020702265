import AntdIcons from './antd/Icons'
import GaxonIcons from './gaxon/Icons'
import InconifyIcons from './iconify/Icons'

export default {
  AntdIcons,
  GaxonIcons,
  InconifyIcons,
}

export const ArrayIconType = {
  antd: 'antd',
  gaxon: 'gaxon',
  iconify: 'iconify',
}
