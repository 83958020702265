import React from 'react'
import Nprogress from 'nprogress'
import ReactPlaceholder from 'react-placeholder'
import { injectIntl } from 'react-intl'
import 'nprogress/nprogress.css'

import 'react-placeholder/lib/reactPlaceholder.css'
import CircularProgress from '@src/components/CircularProgress'

export default function asyncComponent(importComponent: any) {
  class AsyncFunc extends React.Component<
    {
    },
    {
      component: any
    }
  > {
    mounted = false
    constructor(props: { mounted?: boolean }) {
      super(props)
      this.state = {
        component: null,
      }
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      Nprogress.start()
    }

    componentWillUnmount() {
      this.mounted = false
    }

    async componentDidMount() {
      this.mounted = true
      const { default: Component } = await importComponent()
      Nprogress.done()
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        })
      }
    }

    render() {
      const Component = this.state.component || <CircularProgress />
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      )
    }
  }

  return injectIntl(
    AsyncFunc as React.ComponentType<any>
  )
}
