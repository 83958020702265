import { Component } from 'react';
import { Select } from 'antd';
class Enum extends Component<{
  schema?: {
    [x: string]: any
    items?: Array<any>
  }
  disabled?: boolean
  value?: any
  onChange?: (val: any) => void
}> {
  render() {
    return (
      <Select
        disabled={this.props.disabled}
        value={this.props.value}
        onChange={(val) => {
          // let val: any = evt.target.value
          if (val === '') val = null
          if (this.props.onChange) {
            this.props.onChange(val)
          }
        }}
      >
        <Select.Option value="">Không chọn</Select.Option>
        {(this.props.schema?.items ?? []).map((item, index) => (
          <Select.Option value={item.value} key={index}>
            {item.key}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

export default Enum;
