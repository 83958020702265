import React, { FC } from 'react'

export interface ChatCtrlProps {}

const ChatCtrl: FC<ChatCtrlProps> = (props: ChatCtrlProps) => {
  const {} = props
  return <React.Fragment>Form ChatCtrl</React.Fragment>
}

export default ChatCtrl
