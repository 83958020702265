import en_US from './en_US.json';
import user from './user/user_en_EN.json';
// không xóa dòng dưới
/* PLOP_INJECT_IMPORT */

export default {
  ...en_US,
  ...user,
  // không xóa dòng dưới
  /* PLOP_INJECT_EXPORT */
};
